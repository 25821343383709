<template>
  <div class="dealer-subscriptions-tab" v-loading="loading">
    <layout-dealer-card
      toolbar-layout="switch, action_btn"
      search-property="year"
      @loadData="handleSearch"
      v-show="!loading"
    >
      <template v-slot:heading>
        <span class="material-icons-round" style="color: #33a02c">
          card_membership
        </span>
        <p style="margin-left: 10px">
          {{ ftm__capitalize($t("dealer.subscriptions")) }}
        </p>
      </template>

      <template slot="sub-heading"> Summary </template>
      <template slot="table">
        <el-table
          :data="tableData.data"
          show-summary
          border
          :summary-method="getSummaries"
          height="calc(100vh - 220px)"
          style="width: 100%"
          class="thisTable"
        >
          <template v-for="(col, index) of columns">
            <el-table-column
              v-if="col.type != 'parent'"
              :prop="col.prop"
              :label="col.label"
              :key="index"
              :min-width="col.width"
              :align="col.align"
              :fixed="col.fixed"
            >
            </el-table-column>
            <el-table-column
              v-if="col.type == 'parent'"
              :label="col.label"
              :key="col.label"
              :align="col.align"
            >
              <el-table-column
                v-for="(col1, index2) of col.children"
                :key="index2"
                :label="col1.label"
                :prop="col1.prop"
              >
              </el-table-column>
            </el-table-column>
          </template>
        </el-table>
      </template>
    </layout-dealer-card>
  </div>
</template>

<script>
import layoutDealerCard from "@/components/dealer/layoutDealerCard";
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [errorHandlerMixin, filtersMixin],
  components: {
    layoutDealerCard,
  },
  data: function () {
    return {
      loading: false,
      columns: [
        {
          label: "Customer Name",
          prop: "customer_name",
          sort: true,
          width: "200",
          fixed: "left",
        },
        {
          label: "Plan Name",
          prop: "plan_name",
          sort: false,
          width: "100",
          fixed: "left",
        },
        {
          label: "Plan Type",
          prop: "plan_type",
          sort: true,
          width: "100",
          fixed: "left",
        },
        {
          label: "Plan",
          prop: "plan",
          sort: true,
          width: "100",
          fixed: "left",
        },
        {
          label: "End Date",
          prop: "end_date",
          sort: true,
          width: "80",
        },

        {
          label: "Basic Price",
          prop: "basic_price",
          sort: true,
          width: "80",
        },
        {
          type: "parent",
          label: "Discount %",
          align: "center",

          children: [
            {
              label: "Y1/3M",
              prop: "y1_3m",
              sort: true,
              align: "center",
            },
            {
              label: "Y2/6M",
              prop: "y2_6m",
              sort: true,
              align: "center",
            },
            {
              label: "Y3/9M",
              prop: "y3_9m",
              sort: true,
              align: "center",
            },
            {
              label: "Y4",
              prop: "y4",
              sort: true,
              align: "center",
            },
            {
              label: "UL",
              prop: "UL",
              sort: true,
              align: "center",
            },
          ],
        },
      ],
      tableData: {
        year: "2022",
        data: [
          {
            customer_name: "Customer 1",
            plan_name: "Plan - 1Y",
            plan_type: "Basic",
            plan: "Yearly",
            end_date: "Sep-22",
            basic_price: "$1,200",
            y1_3m: "0%",
            y2_6m: "-",
            y3_9m: "-",
            y4: "-",
            UL: "-",
          },
          {
            customer_name: "Customer 2",
            plan_name: "Plan - 3M",
            plan_type: "Basic",
            plan: "Yearly",
            end_date: "Dec-22",
            basic_price: "$22,500",
            y1_3m: "0%",
            y2_6m: "-",
            y3_9m: "0%",
            y4: "-",
            UL: "-",
          },
          {
            customer_name: "Customer 3",
            plan_name: "Plan - 6M",
            plan_type: "Basic",
            plan: "Yearly",
            end_date: "Mar-22",
            basic_price: "$22,500",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "-",
            y4: "-",
            UL: "-",
          },
          {
            customer_name: "Customer 4",
            plan_name: "Plan - 9M",
            plan_type: "Basic",
            plan: "Quarterly",
            end_date: "Jun-24",
            basic_price: "$22,500",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "-",
            y4: "-",
            UL: "-",
          },
          {
            customer_name: "Customer 5",
            plan_name: "Plan - 4Y",
            plan_type: "Basic",
            plan: "Quarterly",
            end_date: "Sep-22",
            basic_price: "$22,500",
            y1_3m: "0%",
            y2_6m: "-",
            y3_9m: "-",
            y4: "-",
            UL: "-",
          },
          {
            customer_name: "Customer 6",
            plan_name: "Plan - 5Y",
            plan_type: "Standard",
            plan: "Quarterly",
            end_date: "Sep-25",
            basic_price: "$1,200",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "0%",
            y4: "-",
            UL: "-",
          },
          {
            customer_name: "Customer 7",
            plan_name: "Plan - UL",
            plan_type: "Standard",
            plan: "Yearly",
            end_date: "Sep-26",
            basic_price: "$1,200",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "0%",
            y4: "0%",
            UL: "-",
          },
          {
            customer_name: "Customer 8",
            plan_name: "Plan - 3M",
            plan_type: "Standard",
            plan: "Yearly",
            end_date: "Sep-22",
            basic_price: "$1,200",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "0%",
            y4: "0%",
            UL: "0%",
          },
          {
            customer_name: "Customer 9",
            plan_name: "Plan - 9M",
            plan_type: "Basic",
            plan: "Yearly",
            end_date: "Sep-31",
            basic_price: "$1,200",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "0%",
            y4: "-",
            UL: "-",
          },
          {
            customer_name: "Customer 10",
            plan_name: "Plan - UL",
            plan_type: "Standard",
            plan: "Yearly",
            end_date: "Sep-26",
            basic_price: "$1,200",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "0%",
            y4: "0%",
            UL: "-",
          },
          {
            customer_name: "Customer 11",
            plan_name: "Plan - 3M",
            plan_type: "Standard",
            plan: "Yearly",
            end_date: "Sep-22",
            basic_price: "$1,200",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "0%",
            y4: "0%",
            UL: "0%",
          },
          {
            customer_name: "Customer 12",
            plan_name: "Plan - 9M",
            plan_type: "Basic",
            plan: "Yearly",
            end_date: "Sep-31",
            basic_price: "$1,200",
            y1_3m: "0%",
            y2_6m: "0%",
            y3_9m: "0%",
            y4: "-",
            UL: "-",
          },
        ],
      },
      searchString: "",
    };
  },
  computed: {
    ...mapGetters("dealer", {
      getDealerSubscriptions: "getDealerSubscriptions",
    }),
    columnsObject() {
      return {
        customer: {
          prop: "customer",
          name: "customer",
          label: "dealer.customer",
          required: true,
          sortable: true,
        },
        plan_type: {
          prop: "type",
          name: "plan_type",
          label: "dealer.plan_type",
          required: true,
          sortable: false,
        },
        duration_years: {
          prop: "duration_years",
          name: "duration_years",
          label: "dealer.no_of_years",
          required: true,
          sortable: false,
        },
        price: {
          name: "price",
          prop: "price",
          label: "dealer.price",
          required: true,
          sortable: false,
        },
        discount: {
          prop: "discount",
          name: "discount",
          label: "dealer.discount",
          required: true,
        },
        final_price: {
          prop: "final_price",
          name: "final_price",
          label: "dealer.final_price",
          required: true,
        },
      };
    },
    filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.title.toLowerCase().includes(this.searchString);
      });
    },
  },
  async mounted() {
    await this.handleLoadData();
  },
  methods: {
    ...mapActions("dealer", {
      fetchAllSubscriptions: "fetchAllSubscriptions",
    }),
    async handleLoadData() {
      try {
        this.loading = true;
        // await this.fetchAllSubscriptions();
        // this.tableData = this.getDealerSubscriptions;
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleSearch(queryObj) {
      this.searchString = queryObj.device_code;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Total Receivables";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
};
</script>

<style lang="scss">
.dealer-subscriptions-tab {
  height: 100%;
  .el-table__body-wrapper {
    height: inherit;
    overflow: auto;
  }
  .el-date-editor {
    .el-input__icon {
      line-height: 32px;
    }
  }
}
</style>
